import React, { useState, useEffect } from "react";
import { getFieldsForRecordType, isTransactionEntity, getPageTitle } from "../../services/standardEntityFieldService";
import { generatePDFContent } from "../../services/pdfContent/pdfContentService";
import { apiCall } from "../../services/api";
import { useHistory, useParams, Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import withAuth from "../../hocs/withAuth";
import _ from "lodash";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PDFGenerator from "../buttons/PdfGenerator";
import { FormViewField } from "../displayFields/FormViewField";
import { isLocatorAccessible } from "../../services/utils";
import { useSnackBar } from "../providers/SnackBarProvider";
import { getHandler } from "../../standardEntities/entityHandlers/EntityHandlerFactory";
import { EntityHandler } from "../../standardEntities/entityHandlers/types";
import FooterBar from "../displayFields/FooterBar";

let globalConstants = {};

function RecordPage({
  fields = [],
  formMinWidth = "40vw",
  formMinHeight = "82vh",
  entityType,
  isValidRecord = () => true,
  creationAllowed = true,
  selectedRecord,
  setSelectedRecord,
  isEditable = () => true,
  hasBackButton = () => true,
  currentUser,
  pageTitle = "",
  setMode = (mode) => {},
}) {
  let { recordType }: any = useParams();
  recordType = entityType || recordType;
  if (_.isEmpty(fields)) {
    fields = getFieldsForRecordType(recordType);
  }

  let recordId = selectedRecord?.id;

  if (_.isEmpty(pageTitle)) {
    pageTitle = getPageTitle(recordType);
  }
  const { dispatch: snackBarDispatch } = useSnackBar();
  const [record, setRecord] = useState(selectedRecord);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const entityHandler: EntityHandler = getHandler(recordType);

  useEffect(() => {
    if (_.isEmpty(recordId)) {
      return;
    }
    setLoading(true);
    apiCall("get", `/api/entity/${recordType}/${recordId}`)
      .then((response) => {
        if (response == null) {
          console.warn("[RecordPage] Null response for records fetch");
          return;
        }
        if (isTransactionEntity(recordType) && response) {
          if (!isLocatorAccessible(response.docLocatorCode)) {
            snackBarDispatch({ type: "set", message: "You are not authorized to view this record", severity: "error" });
            history.push("/");
            return;
          }
        }
        setRecord(response);
      })
      .catch((err) => {
        console.error("[RecordPage] Error fetching records", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [recordId, recordType, history, snackBarDispatch]);

  useEffect(() => {
    setRecord(selectedRecord);
  }, [selectedRecord]);

  //todo: govern this by global constants kind of structure
  if (recordType === "material-inward") {
    if (record?.inventoryValue && record?.basicValue) {
      let dividend = parseFloat(record["inventoryValue"]);
      let divisor = parseFloat(record["basicValue"]);
      if (!isNaN(dividend) && !isNaN(divisor) && divisor !== 0) {
        globalConstants["invByBasic"] = dividend / divisor;
      }
    }
  }

  if (isTransactionEntity(recordType) && record) {
    if (!isLocatorAccessible(record.docLocatorCode)) {
      history.push("/");
    }
  }

  return (
    <div style={{ paddingBottom: "70px" }}>
      <Box sx={{ marginTop: 1.5, marginLeft: 0 }}>
        <form>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 0 }}>
              <h4 className="sub-header"> {pageTitle}</h4>
              {creationAllowed && (
                <Button
                  sx={{
                    width: "80px",
                    height: "40px",
                    fontSize: "0.8rem",
                  }}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  component={Link}
                  to={`/${recordType}/add/`}
                >
                  New
                </Button>
              )}
              {isTransactionEntity(recordType) &&
                recordType !== "work-closure" &&
                (loading || !record?.id ? (
                  <Box sx={{ ml: 22, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <PDFGenerator generateContent={() => generatePDFContent(fields, record, recordType)} record={record} />
                ))}
            </Stack>
            <Paper
              sx={{
                minWidth: formMinWidth,
                minHeight: formMinHeight,
                marginLeft: 0,
                backgroundColor: "#F7F6FF",
              }}
              className="flexPaper"
              elevation={10}
            >
              <Stack sx={{ mt: 4, mb: 4 }} direction="column" alignItems="center" spacing={2}>
                {loading || !record ? (
                  <Box sx={{ ml: 0, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  fields.map((field) => FormViewField({ field, record, entityType: recordType, globalConstants }))
                )}
              </Stack>
            </Paper>
          </Stack>
        </form>
      </Box>

      <FooterBar>
        <Stack direction="row" justifyContent="right" spacing={2} zIndex={11}>
          {hasBackButton() && (
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setSelectedRecord(null);
              }}
            >
              Back
            </Button>
          )}
          {isEditable() && (
            <Button
              variant="contained"
              disabled={record?.cancelled || record?.closed || entityHandler.editDisabled(record)}
              onClick={() => {
                setMode("edit");
                history.push(`/${recordType}/edit`);
              }}
            >
              Edit
            </Button>
          )}
        </Stack>
      </FooterBar>
    </div>
  );
}

export default withAuth(RecordPage);
