import { AbstractMaterialOutwardHandler } from "./AbstractMaterialOutwardHandler";

class StoreTransferHandler extends AbstractMaterialOutwardHandler {
  getEntityType(): string {
    return "store-transfer";
  }
}

const storeTransferHandler = new StoreTransferHandler();
export default storeTransferHandler;
