import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  getColumnsForRecordType,
  getDetailRecordType,
  getExcelColumnsForRecordType,
  getSearchColumnsForRecordType,
  reportEntities,
  getPageTitle,
  isTransactionEntity,
  hasDetailEntity,
} from "../../services/standardEntityFieldService";
import { getDefaultValuesForFields } from "../../services/fieldsUtils";
import _ from "lodash";
import Stack from "@mui/material/Stack";
import { apiCall } from "../../services/api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RecordTable from "../displayFields/RecordTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";
import withAuth from "../../hocs/withAuth";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadIcon from "@mui/icons-material/Upload";
import { Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "../../config/dayjsConfig";
import ExcelReport from "../buttons/ExcelReportGenerator";
import { createExcelReportFromRecords } from "../../services/reportGenerationUtils";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Filter } from "../../types/filter";
import { isEmptyObj } from "../../services/utils";
import CircularProgress from "@mui/material/CircularProgress";
import { getSystemStartDate } from "../../services/dateUtils";
import { getRecordsSearchParams, getRecordsSearchRequestFilters, getRecordsSearchRequestForeignFilters } from "../../services/searchUtils";
import { PicklistSearchField, SearchField } from "../../types/searchFields";
import { getHandler } from "../../standardEntities/entityHandlers/EntityHandlerFactory";

let recordIdToRecordsMap = {};
let searchFieldNameVsFieldMap = {};

function RecordTabularView({
  columns = [],
  searchFields = [],
  creationAllowed = true,
  entityType,
  pageTitle,
  setSelectedRecord = (selectedRecord) => {},
  recordSearchRequest,
  setRecordSeachRequest = (prevSearchRequest) => {},
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
  refreshKey,
}) {
  let { recordType }: any = useParams();
  recordType = entityType || recordType;
  if (_.isEmpty(searchFields)) {
    searchFields = getSearchColumnsForRecordType(recordType);
  }
  creationAllowed = creationAllowed && !reportEntities.includes(recordType);
  let entityHandler = getHandler(recordType);
  const hasDataImport = entityHandler.hasDataImport();

  if (_.isEmpty(pageTitle)) {
    pageTitle = getPageTitle(recordType);
  }
  if (_.isEmpty(columns)) {
    columns = getColumnsForRecordType(recordType);
  }
  let defaultValues = getDefaultValuesForFields(searchFields);

  const [isPageChangeDisabled, setIsPageChangeDisabled] = useState(false);
  const [recordTotalCount, setRecordTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);
  const [debouncedEndDate, setDebouncedEndDate] = useState(endDate);
  const [debouncedSearchRequest, setDebouncedSearchRequest] = useState(recordSearchRequest);
  const [debouncedForeignFilters, setDebouncedForeignFilters] = useState(getForeignFilters(recordSearchRequest));

  const populateSearchFieldsNameToFieldMap = (searchFields) => {
    if (_.isEmpty(searchFields)) {
      return;
    }
    searchFields.forEach((searchField) => {
      searchFieldNameVsFieldMap[searchField.name] = searchField;
    });
  };

  const handleChangePage = (event, newPage) => {
    if (!isTransactionEntity(recordType) || !isPageChangeDisabled) {
      setIsPageChangeDisabled(true);
      setPageNo(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPageNo(0);
  };

  useEffect(() => {
    setError(null);
    setPageNo(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordType, recordSearchRequest, rowsPerPage, startDate, endDate]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedEndDate(endDate);
      setDebouncedStartDate(startDate);
      setDebouncedSearchRequest(recordSearchRequest);
      setDebouncedForeignFilters(getForeignFilters(recordSearchRequest));
    }, 400);
    return () => {
      clearTimeout(handler); // Clear timeout if recordSearchRequest changes again within 600ms
    };
  }, [recordSearchRequest, startDate, endDate]);

  const request = {
    page: { page: pageNo, size: rowsPerPage },
    filter: getRecordsSearchRequestFilters(debouncedSearchRequest, searchFieldNameVsFieldMap),
    searchParams: getRecordsSearchParams(debouncedSearchRequest, searchFieldNameVsFieldMap),
    query,
    timeFilter: { startDate: debouncedStartDate, endDate: debouncedEndDate },
    foreignFilters: debouncedForeignFilters,
    returnTotalCount: !isTransactionEntity(recordType) && !reportEntities.includes(recordType),
    sortOrders: [["id", sortOrder]],
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["searchValues", recordType, JSON.stringify(request), refreshKey],
    queryFn: () => fetchLookupValues(request),
    refetchOnWindowFocus: false,
    //2 minutes
    gcTime: 120000,
    staleTime: 120000,
  });

  // Handle data changes with useEffect
  useEffect(() => {
    console.log("data is", data);
    if (data) {
      if (data == null) {
        console.warn("[RecordTabularView] Null response for records fetch");
        return;
      }
      setFilteredRecords(data.results);
      setRecordTotalCount(data.totalCount);
      addRecordsToMap(data.results); // Ensure this function is defined
      setIsPageChangeDisabled(false);
    }
  }, [data]);

  const fetchLookupValues = async (request) => {
    return await apiCall("post", `/api/entity/${recordType}/search`, request);
  };

  const toInputUppercase = (e, field) => {
    if (!field || field.type !== "number" || !field.decimal) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };

  const getExcelReportData = async (
    page = { page: 0, size: recordTotalCount },
    searchRequest = recordSearchRequest,
    searchQuery = query,
    timeFilter = { startDate, endDate }
  ) => {
    try {
      let request = {
        page,
        filter: getRecordsSearchRequestFilters(searchRequest, searchFieldNameVsFieldMap),
        searchParams: getRecordsSearchParams(debouncedSearchRequest, searchFieldNameVsFieldMap),
        foreignFilters: debouncedForeignFilters,
        query: searchQuery,
        timeFilter,
      };
      const response = await apiCall("post", `/api/entity/${recordType}/search`, request);
      let { results } = response;
      let excelColumns = getExcelColumnsForRecordType(recordType);
      return results ? createExcelReportFromRecords(results, excelColumns) : [];
    } catch (error) {
      console.error("Error fetching records:", error);
      throw error; // Re-throw the error for the caller to handle
    }
  };

  const getExcelReportDataDtl = async (
    page = { page: 0, size: -1 },
    searchRequest = recordSearchRequest,
    searchQuery = query,
    timeFilter = { startDate, endDate }
  ) => {
    let detailRecordType = getDetailRecordType(recordType);
    try {
      let request = {
        page,
        foreignFilters: {
          [recordType]: getRecordsSearchRequestFilters(searchRequest, searchFieldNameVsFieldMap),
          ...debouncedForeignFilters,
        },
        searchParams: getRecordsSearchParams(debouncedSearchRequest, searchFieldNameVsFieldMap),
        query: searchQuery,
        timeFilter,
      };
      const response: any = await apiCall("post", `/api/entity/${detailRecordType}/search`, request);
      let { results } = response;
      let excelColumns = getExcelColumnsForRecordType(detailRecordType);
      return results ? createExcelReportFromRecords(results, excelColumns) : [];
    } catch (error) {
      console.error("Error fetching records:", error);
      throw error; // Re-throw the error for the caller to handle
    }
  };

  const handleClearInput = () => {
    setRecordSeachRequest(defaultValues);
    setError(null);
    setPageNo(0);
    setQuery("");
    refetch();
  };

  const handleRefetch = () => {
    setError(null);
    setPageNo(0);
    refetch();
  };

  const handleRecordSearchRequestChange = (e) => {
    let newSearchRequest = { ...recordSearchRequest };
    newSearchRequest[e.target.name] = e.target.value;
    setRecordSeachRequest(newSearchRequest);
    setError(null);
    setPageNo(0);
  };

  const handleRecordSelection = async (recordId) => {
    let selectedRecord = recordIdToRecordsMap[recordId];
    if (!reportEntities.includes(recordType)) {
      setSelectedRecord(selectedRecord);
    }
  };

  function getForeignFilters(searchRequest: any) {
    let foreignFiltersMap: Map<string, Filter> = getRecordsSearchRequestForeignFilters(searchRequest, searchFieldNameVsFieldMap);
    //convert it to request friendly form
    let foreignFilters: Record<string, Filter> = {};
    if (foreignFiltersMap instanceof Map) {
      foreignFilters = Object.fromEntries(foreignFiltersMap);
    }
    return foreignFilters;
  }

  function addRecordsToMap(newRecords) {
    if (!_.isEmpty(newRecords)) {
      recordIdToRecordsMap = {};
      newRecords.forEach((record) => {
        recordIdToRecordsMap[record.id] = record;
      });
    }
  }

  let excelReportTitle: string;
  if (recordType === "stock") {
    excelReportTitle = "Summary";
  } else if (hasDetailEntity(recordType)) {
    excelReportTitle = "Master";
  } else {
    excelReportTitle = null;
  }

  populateSearchFieldsNameToFieldMap(searchFields);
  return (
    <>
      <Box sx={{ marginLeft: 0, marginTop: 2, width: "80vw" }}>
        <form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Stack
              sx={{ mb: 1, alignItems: "center" }}
              direction="row"
              // alignItems="center"
              spacing={3}
            >
              <h4 className="sub-header"> {pageTitle} </h4>
              <Stack
                sx={{ mb: 1, alignItems: "center" }}
                direction="row"
                // alignItems="center"
                spacing={1}
              >
                {creationAllowed && (
                  <Button
                    sx={{
                      width: "80px",
                      height: "40px",
                      fontSize: "0.8rem",
                    }}
                    disabled={isLoading}
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    component={Link}
                    to={`/${recordType}/add/`}
                  >
                    New
                  </Button>
                )}
                <ExcelReport
                  fechData={getExcelReportData}
                  disabled={recordTotalCount === 0 || isLoading}
                  fileName={pageTitle}
                  title={excelReportTitle}
                />
                {(hasDetailEntity(recordType) || recordType === "stock") && (
                  <ExcelReport
                    fechData={getExcelReportDataDtl}
                    disabled={recordTotalCount === 0 || isLoading}
                    fileName={pageTitle + " Details"}
                    // color="secondary"
                    title="Details"
                    setError={setError}
                  />
                )}
                {hasDataImport && (
                  <Button
                    variant="outlined"
                    sx={{
                      height: "40px",
                      fontSize: "0.8rem",
                    }}
                    startIcon={<UploadIcon />}
                    component={Link}
                    to={`/${recordType}/import/`}
                  >
                    Import
                  </Button>
                )}
              </Stack>
            </Stack>
          </div>

          {isTransactionEntity(recordType) && (
            <Box sx={{ ml: 1, mb: 0, height: "4ch" }}>
              <DatePicker
                name="from"
                label="From"
                minDate={getSystemStartDate()}
                value={startDate}
                maxDate={endDate}
                sx={{
                  mt: 0,
                  mr: 2,
                  width: "17ch",
                  "& .MuiInputBase-input": {
                    fontSize: "smaller",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "smaller",
                  },
                }}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                onChange={(date) => {
                  handleStartDateChange(date);
                }}
              />
              <DatePicker
                name="to"
                label="To"
                minDate={startDate}
                value={endDate}
                maxDate={dayjs(new Date())}
                sx={{
                  mt: 0,
                  mr: 2,
                  width: "17ch",
                  "& .MuiInputBase-input": {
                    fontSize: "smaller",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "smaller",
                  },
                }}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                onChange={(date) => {
                  handleEndDateChange(date);
                }}
              />
            </Box>
          )}
          <Box sx={{ mt: 1, mb: 3, height: "4ch" }}>
            <Stack sx={{ p: 1 }} direction="row" alignItems="top" spacing={1}>
              {searchFields.map((searchField) => getJsxForField(searchField))}
              <Tooltip title="Clear">
                <IconButton aria-label="reset" color="error" onClick={handleClearInput}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Refresh">
                <IconButton aria-label="reset" color="success" onClick={handleRefetch}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              {isTransactionEntity(recordType) && (
                <Tooltip title="Sort">
                  <Button color="secondary" endIcon={<SwapVertIcon />} onClick={handleSortToggle}>
                    {sortOrder}
                  </Button>
                </Tooltip>
              )}
            </Stack>
          </Box>
          {/* <Divider sx={{ mb: 2, width: "100%" }} /> */}
        </form>
      </Box>
      <Box sx={{ marginTop: 0 }}>
        {error && (
          <div
            style={{
              width: "80rem",
              justifyContent: "center",
              marginTop: "1rem",
            }}
            className="alert alert-danger"
          >
            {error}
          </div>
        )}
        {isLoading ? (
          <Box sx={{ ml: 22, display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack
            direction="row"
            alignItems="centertop"
            sx={{
              marginTop: "1rem",
              "& .MuiTableHead-root .MuiTableCell-root": {
                whiteSpace: "nowrap", // Prevent text wrapping in table header cells
              },
            }}
          >
            <RecordTable
              records={filteredRecords}
              handleRecordSelection={handleRecordSelection}
              recordsTotalCount={recordTotalCount}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={pageNo}
              rowsPerPage={rowsPerPage}
              columns={columns}
              tableContainerHeight={isTransactionEntity(recordType) ? "70vh" : "76vh"}
            />
          </Stack>
        )}
      </Box>
    </>
  );

  function handleSortToggle() {
    let newSortOrder = "DESC";
    setSortOrder((prevSortOrder) => {
      newSortOrder = prevSortOrder === "ASC" ? "DESC" : "ASC";
      return newSortOrder;
    });
  }

  function getJsxForField(searchField: SearchField) {
    let width = searchField.props?.widthFactor ? 20 * searchField.props.widthFactor : 20;
    if (searchField.type === "text" || searchField.type === "rawSearch") {
      return (
        <TextField
          key={searchField.name}
          id={searchField.name}
          name={searchField.name}
          label={searchField.label}
          size="small"
          onChange={handleRecordSearchRequestChange}
          onInput={(e) => toInputUppercase(e, searchField)}
          type="text"
          sx={{
            mb: 10,
            width: `${width}ch`,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
          }}
          value={recordSearchRequest?.[searchField.name] ?? ""}
        />
      );
    } else if (searchField.type === "number") {
      return (
        <TextField
          key={searchField.name}
          id={searchField.name}
          name={searchField.name}
          label={searchField.label}
          size="small"
          onChange={handleRecordSearchRequestChange}
          type="number"
          sx={{
            // mb: 10,
            width: `${width}ch`,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
          }}
          value={recordSearchRequest?.[searchField.name] ?? ""}
        />
      );
    } else if (searchField.type === "checkbox") {
      return (
        <FormGroup key={searchField.name}>
          <FormControlLabel
            required
            control={
              <Switch
                key={searchField.name}
                checked={recordSearchRequest ? recordSearchRequest[searchField.name] : false}
                name={searchField.name}
                onChange={handleRecordSearchRequestChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={searchField.label}
          />
        </FormGroup>
      );
    } else if (searchField.type === "picklist") {
      let picklistValues = (searchField as PicklistSearchField).values;
      return (
        <FormControl key={searchField.name} sx={{ width: `${width}ch` }}>
          <InputLabel
            id={searchField.name}
            sx={{
              fontSize: "smaller",
              lineHeight: "1.5", // Adjust to match text alignment
              marginTop: !(recordSearchRequest && !isEmptyObj(recordSearchRequest[searchField.name])) ? "-7px" : null,
            }}
          >
            {searchField.label}{" "}
          </InputLabel>
          <Select
            sx={{
              mb: 1,
              padding: "2px 2px",
              "& .MuiInputBase-input": {
                fontSize: "smaller",
                padding: "5px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "smaller",
              },
            }}
            size="small"
            labelId={searchField.name}
            id={searchField.name}
            label={searchField.label}
            name={searchField.name}
            value={recordSearchRequest && !isEmptyObj(recordSearchRequest[searchField.name]) ? recordSearchRequest[searchField.name] : ""}
            onChange={handleRecordSearchRequestChange}
            key={searchField.name}
          >
            <MenuItem value="" disabled>
              {searchField.label}
            </MenuItem>
            {picklistValues?.map((valueObject) => (
              <MenuItem key={valueObject.value} value={valueObject.value}>
                {valueObject.label}
              </MenuItem>
            ))}
            {recordSearchRequest && !isEmptyObj(recordSearchRequest[searchField.name]) && (
              <MenuItem value="" sx={{ backgroundColor: "lightgray" }}>
                Clear Selection
              </MenuItem>
            )}
          </Select>
        </FormControl>
      );
    } else {
      return <></>;
    }
  }
}

export default withAuth(RecordTabularView);
