import React from "react";
import { Paper } from "@mui/material";

const FooterBar = ({ children, sx = {}, elevation = 8 }) => {
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: 2,
        py: 0.8,
        px: 9,
        backgroundColor: "#F7F6FF",
        zIndex: 1,
        boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
        ...sx,
      }}
      elevation={elevation}
    >
      {children}
    </Paper>
  );
};

export default FooterBar;
