import { DefaultHandler } from "./DefaultHandler";
class GatePassHandler extends DefaultHandler {
  getFormWidth(): string {
    return "80vw";
  }
  getEntityType(): string {
    return "gate-pass";
  }

  editDisabled(record: any): boolean {
    return record?.delivered;
  }
}
const gatePassHandler = new GatePassHandler();
export default gatePassHandler;
