import { AbstractMaterialOutwardHandler } from "./AbstractMaterialOutwardHandler";

class MaterialOutwardHandler extends AbstractMaterialOutwardHandler {
  getEntityType(): string {
    return "material-outward";
  }
}

const materialOutwardHandler = new MaterialOutwardHandler();
export default materialOutwardHandler;
