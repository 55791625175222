import React, { useState, useEffect } from "react";
import {
  getDefaultValuesForRecordType,
  getFieldsForRecordType,
  getLeafFieldsForRecordType,
  getPageTitle,
} from "../../services/standardEntityFieldService";
import { apiCall } from "../../services/api";
import { useHistory, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import ConfirmationButtonComponent from "../buttons/ConfirmationButtonComponent";
import CircularProgress from "@mui/material/CircularProgress";
import withAuth from "../../hocs/withAuth";
import _, { debounce } from "lodash";
import DeleteButtonComponent from "../buttons/DeleteButtonComponent";
import { FormInputField } from "../inputFields/FormInputField";
import { isValidRecord, canEditEntity } from "../../services/utils";
import { Field } from "../../types/field";
import { useSnackBar } from "../providers/SnackBarProvider";
import { EntityHandler } from "../../standardEntities/entityHandlers/types";
import { getHandler } from "../../standardEntities/entityHandlers/EntityHandlerFactory";
import FooterBar from "../displayFields/FooterBar";

interface RecordAdditionFormProps {
  fields?: Field[];
  formMinHeight?: string;
  entityType: string;
  handleEntityDecoration?: (record: any) => any;
  setMode?: (mode: string) => void;
  originalPageTitle?: string;
  currentUser: any;
  originalIsUpdateForm?: boolean;
  selectedRecord?: any;
  setSelectedRecord?: (selectedRecord: any) => void;
  incrementRefreshKey?: () => void;
  refreshKey?: number;
}

const RecordAdditionForm: React.FC<RecordAdditionFormProps> = ({
  fields = [],
  formMinHeight = "82vh",
  entityType,
  handleEntityDecoration = (record) => {
    return record;
  },
  setMode = (mode) => {},
  originalPageTitle = "",
  currentUser,
  originalIsUpdateForm = false,
  selectedRecord = null,
  setSelectedRecord = (selectedRecord) => {},
  incrementRefreshKey = () => {},
  refreshKey = 0,
}) => {
  let { recordType }: any = useParams();
  recordType = entityType || recordType;
  if (_.isEmpty(fields)) {
    fields = getFieldsForRecordType(recordType);
  }
  let initialRecord = selectedRecord != null ? { ...selectedRecord } : { ...getDefaultValuesForRecordType(recordType), id: null, cancelled: false };
  let isApproved = initialRecord.approved;

  let defaultValues = getDefaultValuesForRecordType(recordType);
  const { dispatch: snackBarDispatch } = useSnackBar();
  const [error, setError] = useState(null);
  const [record, setRecord] = useState(initialRecord);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const history = useHistory();
  const isUpdateForm = originalIsUpdateForm;
  const entityHandler: EntityHandler = getHandler(recordType);
  let isValidEntityRecord = entityHandler.isValidRecord;
  let handleEntitySpecificChange = entityHandler.handleEntitySpecificChange;
  let formDisabled = entityHandler.formDisabled;
  let formMinWidth = entityHandler.getFormWidth();
  let canDelete = entityHandler.canDelete;
  let isUnSubmitRequired = entityHandler.isUnSubmitRequired;

  let entityPageTitle = getPageTitle(recordType);
  let pageTitle = originalPageTitle || (isUpdateForm ? `Update ${entityPageTitle}` : `Add ${entityPageTitle}`);

  useEffect(() => {
    if (!canEditEntity(recordType, currentUser)) {
      history.push("/");
    }
    if (selectedRecord) {
      return;
    }
    setRecord({ ...defaultValues, id: null, cancelled: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordType, currentUser, history]);

  const setSnackBarMessage = (message, severity = "success") => {
    snackBarDispatch({ type: "set", message, severity });
    console.log(message);
  };

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    handleRecordChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, isLinkedField?: boolean) => {}
  ) => {
    const { name, value, type, checked }: any = { ...e.target };
    setIsEdited(true);
    setSnackBarMessage(null);
    await handleEntitySpecificChange({
      record: { ...record },
      name,
      value,
      type,
      checked,
      setFormErrors,
      setError,
      handleRecordChange,
    });
  };

  const handleSubmit = (record) => {
    if (!isValidateRecord(record)) {
      return;
    }

    if (isUpdateForm) {
      updateRecord(record);
    } else {
      saveNewRecord(record);
    }
  };

  const isValidateRecord = (record) => {
    return isValidRecord({
      record,
      leafFields: getLeafFieldsForRecordType(recordType),
      setError,
      setFormErrors,
      isValidEntityRecord,
    });
  };

  const handleUnapprove = debounce((record) => {
    setLoading(true);
    apiCall("put", `/api/entity/${recordType}/${record.id}/toggle?approved=false`, record)
      .then(async (response) => {
        setSnackBarMessage("Record Updated Successfully");
        await fetchAndUpdateSelectedRecord(record.id);
        setMode("view");
        history.push(`/${recordType}/`);
      })
      .catch((err) => {
        console.error("error inside unapproval", err);
        setSnackBarMessage("Error Occured While Un-Submitting", "error");
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 200);

  const updateRecord = debounce((record) => {
    setLoading(true);
    let finalRecord = handleEntityDecoration(record);
    apiCall("put", `/api/entity/${recordType}/${record.id}`, finalRecord)
      .then(async (response) => {
        setSnackBarMessage("Record Updated Successfully");
        await fetchAndUpdateSelectedRecord(record.id);
        setMode("view");
        history.push(`/${recordType}/`);
      })
      .catch((err) => {
        console.error("error inside calculator", err);
        setSnackBarMessage("Error Occured While Updating", "error");
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 200);

  const saveNewRecord = debounce((record) => {
    let finalRecord = handleEntityDecoration(record);
    setLoading(true);
    apiCall("post", `/api/entity/${recordType}/`, finalRecord)
      .then(async (response) => {
        let id = response.id;
        setSnackBarMessage(`Record Created Successfully with id ${id}`);
        await fetchAndUpdateSelectedRecord(id);
        setMode("view");
        history.push(`/${recordType}/`);
      })
      .catch((err) => {
        console.error("error inside save new record", err);
        setSnackBarMessage("Error Occured While Saving", "error");
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 200);

  const handleDeleteRecord = () => {
    setLoading(true);
    apiCall("delete", `/api/entity/${recordType}/${record.id}`)
      .then((response) => {
        setSnackBarMessage("Record Deleted Successfully");
        setMode("view");
        setSelectedRecord(null);
        incrementRefreshKey();
        history.push(`/${recordType}/`);
      })
      .catch((err) => {
        console.error("[RecordAdditionForm] error inside handle delete ", err);
        setSnackBarMessage("Error Occured While Deleting", "error");
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let globalConstants = { ...record };
  //todo: govern this by global constants kind of structure
  if (recordType === "material-inward") {
    globalConstants["invByBasic"] = 1;
    if (record?.inventoryValue && record?.basicValue) {
      let dividend = parseFloat(record["inventoryValue"]);
      let divisor = parseFloat(record["basicValue"]);
      if (!isNaN(dividend) && !isNaN(divisor) && divisor !== 0) {
        globalConstants["invByBasic"] = dividend / divisor;
      }
    }
  }

  const handleKeyPress = (event) => {
    try {
      if (event.key === "Enter") {
        let form = event.target.form;
        let index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 2].focus();
        event.preventDefault();
      }
    } catch (e) {
      console.error("[RecordAdditionForm] Exception occured while handling key press", e);
    }
  };

  const fetchAndUpdateSelectedRecord = async (recordId) => {
    setLoading(true);
    try {
      let response = await apiCall("get", `/api/entity/${recordType}/${recordId}`);
      if (response == null) {
        console.warn("[RecordPage] Null response for records fetch");
        return;
      }
      incrementRefreshKey();
      setSelectedRecord(response);
      return response;
    } catch (err) {
      console.error("[RecordPage] Error fetching records", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ paddingBottom: "70px" }}>
      <Box sx={{ marginTop: 1.5 }}>
        <div onKeyDown={handleKeyPress}>
          <Stack direction="column" alignItems="center" spacing={2}>
            <h4 className="sub-header"> {pageTitle}</h4>
            <Paper
              sx={{
                minWidth: formMinWidth,
                minHeight: formMinHeight,
                backgroundColor: "#F7F6FF",
              }}
              className="flexPaper"
              elevation={10}
            >
              <Stack sx={{ mt: 4, mb: 4 }} direction="column" alignItems="center" spacing={2}>
                {loading ? (
                  <Box sx={{ ml: 22, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  fields.map((field, index) => (
                    <FormInputField
                      key={field.name + index}
                      field={field}
                      leafFields={getLeafFieldsForRecordType(recordType)}
                      record={record}
                      setRecord={setRecord}
                      isUpdateForm={isUpdateForm}
                      formDisabled={formDisabled(record)}
                      formApproved={isUnSubmitRequired(isApproved)}
                      formCancelled={record.cancelled}
                      handleChangeInternal={handleChange}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      entityType={recordType}
                      setError={setError}
                      globalConstants={globalConstants}
                      refreshKey={refreshKey}
                    />
                  ))
                )}
              </Stack>
            </Paper>
            {error && (
              <Alert
                onClose={() => {
                  setError(null);
                  snackBarDispatch({ type: "clear" });
                }}
                severity="error"
              >
                {error}
              </Alert>
            )}
            {!loading && (
              <FooterBar>
                <Stack direction="row" justifyContent="right" spacing={2} zIndex={12}>
                  {!isEdited ? (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setMode("view");
                        history.push(`/${recordType}/`);
                      }}
                      disabled={loading}
                    >
                      Back
                    </Button>
                  ) : (
                    <ConfirmationButtonComponent
                      handleSubmit={() => {
                        setMode("view");
                        history.push(`/${recordType}/`);
                      }}
                      confirmationTitle="Back"
                      confirmationBody={`Are You Sure,  All Your Unsaved Changes Will Be Lost`}
                      submitBody={record}
                      buttonText={"Back"}
                      color="error"
                      disabled={loading}
                    />
                  )}
                  {isUpdateForm && canDelete(recordType, currentUser, record) && (
                    <DeleteButtonComponent handleDelete={handleDeleteRecord} itemType={recordType} disabled={loading} />
                  )}
                  {isUnSubmitRequired(isApproved) ? (
                    <ConfirmationButtonComponent
                      handleSubmit={handleUnapprove}
                      confirmationTitle="UnSubmit"
                      confirmationBody="Are You Sure You Wish To Un-Submit/Un-Approve"
                      submitBody={record}
                      buttonText={"UnSubmit"}
                      disabled={loading}
                    />
                  ) : (
                    <ConfirmationButtonComponent
                      handleSubmit={handleSubmit}
                      confirmationTitle="Save Record"
                      confirmationBody="Are You Sure You Wish To Proceed?"
                      submitBody={record}
                      buttonText={isUpdateForm ? "Update" : "Save"}
                      disabled={!isEdited || loading}
                      shouldOpen={isValidateRecord}
                    />
                  )}
                </Stack>
              </FooterBar>
            )}
          </Stack>
          <h4> </h4>
        </div>
      </Box>
    </div>
  );
};

export default withAuth(RecordAdditionForm);
