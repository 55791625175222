import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";

export const itemTypeValues = [
  { value: "FIM", label: "Free Issue Material" },
  { value: "NFIM", label: "Non Free Issue Material" },
];

export const itemFields: Field[] = [
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "section3",
    type: "formSection",
    childFields: [
      {
        name: "itemType",
        label: "Item Type",
        type: "picklist",
        defaultValue: "FIM",
        values: itemTypeValues,
        props: {
          width: "12vw",
        },
        linkedFields: [
          {
            name: "ownerCompanyName",
            value: "",
          },
          {
            name: "ownerCode",
            value: "",
          },
          {
            name: "itemCustCode",
            value: "",
          }
        ],
      },
      {
        name: "section3",
        type: "formSection",
        visibility: { type: "IN", field: "itemType", values: ["FIM"] },
        childFields: [
          {
            name: "itemCustCode",
            label: "Part Code",
            type: "text",
            defaultValue: null,
            allowNull: false,
            props: {
              width: "12vw",
            },
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        visibility: { type: "NIN", field: "itemType", values: ["FIM"] },
        childFields: [
          {
            name: "itemCustCode",
            label: "Part Code",
            type: "text",
            defaultValue: null,
            allowNull: true,
            props: {
              width: "12vw",
            },
          },
        ],
      },
    ],
  },
  {
    name: "section3",
    type: "formSection",
    childFields: [
      {
        name: "uom",
        label: "UOM",
        type: "picklist",
        defaultValue: "NOS",
        values: [
          { value: "NOS", label: "Number" },
          { value: "MTR", label: "Meter" },
          { value: "KG", label: "Kg" },
          { value: "SET", label: "Set" },
        ],
        linkedFields: [
          {
            name: "decimalFlg",
            value: false,
          },
        ],
        props: {
          width: "12vw",
        },
      },
      {
        name: "itemGroupId",
        label: "Group Id",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "12vw",
        },
      },
    ],
  },
  {
    name: "section3",
    type: "formSection",
    childFields: [
      {
        name: "itemGroupName",
        label: "Item Group Name",
        type: "lookup",
        props: {
          width: "25vw",
        },
        lookupType: "itemGroup",
        allowUpdate: true,
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "itemGroupId",
              resolveValue: true,
              valueFieldName: "itemGroupId",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "itemGroupId",
          },
        ],
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "hsnCode",
        label: "HSN Code",
        type: "text",
        allowUpdate: true,
      },
      {
        name: "unitRate",
        label: "Indicative Unit Rate",
        type: "number",
        decimal: true,
        defaultValue: 0,
        allowUpdate: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: { type: "IN", field: "itemType", values: ["FIM"] },
    childFields: [
      {
        name: "ownerCompanyName",
        label: "Owner Company",
        type: "lookup",
        lookupType: "company",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "ownerFlg",
              values: [true],
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "ownerCode",
          },
        ],
        props: {
          width: "25vw",
        },
        disability: {
          type: "IN",
          field: "itemType",
          values: ["NFIM"],
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: { type: "NIN", field: "itemType", values: ["FIM"] },
    childFields: [
      {
        name: "ownerCompanyName",
        label: "Owner Company",
        type: "lookup",
        lookupType: "company",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "partnerFlg",
              values: [true],
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "ownerCode",
          },
        ],
        props: {
          width: "25vw",
        },
      },
    ],
  },
  {
    name: "section3",
    type: "formSection",
    childFields: [
      {
        name: "ownerCode",
        label: "Owner Code",
        type: "text",
        props: {
          width: "8vw",
        },
        autoFilled: true,
        allowNull: true,
      },
      {
        name: "id",
        label: "Item Id",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
      {
        name: "itemCode",
        label: "Item Code",
        type: "text",
        allowNull: true,
        autoFilled: true,
        props: {
          width: "8vw",
        },
      },
    ],
  },
  {
    name: "oitemName",
    label: "Original Item Name",
    type: "text",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "decimalFlg",
        label: "Decimal",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
        disability: {
          type: "NIN",
          field: "uom",
          values: ["MTR", "KG"],
        },
      },
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
      },
    ],
  },
];

export const itemColumns = [
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "unitRate",
    label: "Unit Rate",
    type: "number",
  },
  {
    name: "itemType",
    label: "Type",
    type: "text",
  },
  {
    name: "ownerCode",
    label: "Company",
    type: "text",
  },
  {
    name: "itemGroupName",
    label: "Item Group",
    type: "number",
  },
  {
    name: "decimalFlg",
    label: "Decimal",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "Item Id",
    type: "text",
  },
];

export const itemExcelColumns = [
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "unitRate",
    label: "Unit Rate",
    type: "text",
  },
  {
    name: "hsnCode",
    label: "HSN Code",
    type: "text",
  },
  {
    name: "itemType",
    label: "Type",
    type: "text",
    format: (value) => translatePicklistValue(value, itemTypeValues),
  },
  {
    name: "ownerCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "itemGroupName",
    label: "Item Group",
    type: "text",
  },
  {
    name: "itemGroupId",
    label: "Group Id",
    type: "text",
  },
  {
    name: "decimalFlg",
    label: "Decimal",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCode",
    label: "Item Code",
    type: "text",
  },
];

export const itemSearchColumns = [
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
    props: {
      widthFactor: 1.6,
    },
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "picklist",
    values: [
      { value: "NOS", label: "Number" },
      { value: "MTR", label: "Meter" },
      { value: "KG", label: "Kg" },
      { value: "SET", label: "Set" },
    ],
  },
  {
    name: "itemType",
    label: "Type",
    type: "picklist",
    values: [
      { value: "FIM", label: "FIM" },
      { value: "NFIM", label: "NFIM" },
    ],
  },
  {
    name: "ownerCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "itemGroupName",
    label: "Item Group",
    type: "text",
    props: {
      widthFactor: 1.6,
    },
  },
  {
    name: "decimalFlg",
    label: "Decimal",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
];
