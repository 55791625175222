import { translatePicklistValue } from "../services/fieldsUtils";
import { materialTypeValues, inTransactionTypeValues } from "./materialInward";
import { projectTypeValues } from "../standardEntities/project";
import { locatorTypeValues } from "../standardEntities/locator";
import { Column } from "../types/column";
import { SearchField } from "../types/searchFields";

export const entityType = "stock";

export const stockViewColumns: Column[] = [
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "locatorType",
    label: "Loc",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Company Name",
    type: "text",
  },
  // {
  //   name: "projectType",
  //   label: "Type",
  //   type: "text",
  //   format: (value) => translatePicklistValue(value, projectTypeValues),
  // },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  // {
  //   name: "itemId",
  //   label: "Item Id",
  //   type: "text",
  // },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalAvailableQuantity",
    label: "Available",
    type: "text",
  },
  // {
  //   name: "totalAvailableAmount",
  //   label: "Available Amt",
  //   type: "text",
  // },
  {
    name: "totalPhysicalQuantity",
    label: "Physical",
    type: "text",
  },
  // {
  //   name: "totalPhysicalAmount",
  //   label: "Physical Amt",
  //   type: "text",
  // },
];

export const stockSearchColumns: SearchField[] = [
  {
    name: "materialInwardDtl.docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "companyCodeRegex",
    label: "Company Code",
    type: "rawSearch",
  },
  {
    name: "$project.projectType$",
    label: "Project Type",
    type: "picklist",
    values: projectTypeValues,
  },
  {
    name: "materialInwardDtl.projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "docLocator.state.stateName",
    label: "State",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "materialInwardDtl.itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "materialType",
    label: "Material Type",
    type: "picklist",
    values: materialTypeValues,
  },
];

export const stockViewExcelColumns: Column[] = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "locatorType",
    label: "Locator Type",
    type: "picklist",
    values: locatorTypeValues,
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "companyCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "ownerCode",
    label: "Project Owner",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockViewDetailExcelColumns: Column[] = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Inward Locator Name",
    type: "text",
  },
  {
    name: "docLocatorType",
    label: "Locator Type",
    type: "picklist",
    values: locatorTypeValues,
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "docOwnerCode",
    label: "Project Owner",
    type: "text",
  },
  {
    name: "docOwnerName",
    label: "Project Owner Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "picklist",
    values: projectTypeValues,
  },
  {
    name: "docVirtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "picklist",
    values: inTransactionTypeValues,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "picklist",
    values: materialTypeValues,
  },
  {
    name: "submit",
    label: "Submit",
    type: "checkbox",
  },
  {
    name: "id",
    label: "MIR Row Id",
    type: "text",
  },
  {
    name: "mirLotId",
    label: "MIR Lot",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalQuantity",
    label: "Inward Qty",
    type: "number",
  },
  {
    name: "totalAmount",
    label: "Inward Amt",
    type: "number",
  },
  {
    name: "availableQuantity",
    label: "Available Qty",
    type: "number",
  },
  {
    name: "availableAmount",
    label: "Available Amt",
    type: "number",
  },
  {
    name: "physicalQuantity",
    label: "Physical Qty",
    type: "number",
  },
  {
    name: "physicalAmount",
    label: "Physical Amt",
    type: "number",
  },
];
