import _ from "lodash";
import { Filter, CompoundFilter, LeafFilter } from "../types/filter";
import { getConfigs } from "../config/configHolder";
import { isEmptyObj } from "./utils";

function extractLeafFilterValues(filter: LeafFilter, record: any, globalConstants?: any[]) {
  const { field, valueFieldName } = filter;
  let values = filter.values;
  let context = { ...record, ...globalConstants, ...getConfigs() };
  if (valueFieldName) {
    values = context[valueFieldName];
  }
  return { field, values };
}

export function evaluateFilter(filter: Filter, record: any, globalConstants?: any) {
  let { type } = filter;

  let context = { ...record, ...globalConstants, ...getConfigs() };

  if (!record) {
    return false;
  }
  switch (type) {
    case "NOT": {
      let { filters } = filter as CompoundFilter;
      let andFilter: CompoundFilter = { type: "AND", filters };
      return !evaluateFilter(andFilter, record, globalConstants);
    }
    case "AND": {
      console.log("archit AND", filter);
      let { filters } = filter as CompoundFilter;
      if (!filters) {
        return true;
      }
      for (let filter of filters) {
        if (!evaluateFilter(filter, record, globalConstants)) {
          return false;
        }
      }
      return true;
    }
    case "OR": {
      let { filters } = filter as CompoundFilter;
      if (!filters) {
        return true;
      }
      for (let filter of filters) {
        if (evaluateFilter(filter, record, globalConstants)) {
          return true;
        }
      }
      return false;
    }
    case "EQUALS": {
      let { field, values } = extractLeafFilterValues(filter as LeafFilter, record, globalConstants);
      return context[field] === Array.isArray(values) ? values[0] : values;
    }
    case "GTE": {
      console.log("archit GTE", filter);
      let { field, values } = extractLeafFilterValues(filter as LeafFilter, record, globalConstants);
      console.log("archit GTE", context[field], values);
      return context[field] >= values;
    }
    case "NOT_EQUALS": {
      let { field, values } = extractLeafFilterValues(filter as LeafFilter, record, globalConstants);
      return context[field] !== values;
    }
    case "CONTAINS": {
      let { field, values }: { field: string; values: any[] } = extractLeafFilterValues(filter as LeafFilter, record, globalConstants);
      let contextValue: any[] = context[field] instanceof Array ? context[field] : [context[field]];
      return values.every((value) => contextValue.includes(value));
    }
    case "IN": {
      let { field, values } = extractLeafFilterValues(filter as LeafFilter, record, globalConstants);
      // eslint-disable-next-line eqeqeq
      return values.some((value) => value == context[field]);
    }
    case "NIN": {
      let { field, values } = extractLeafFilterValues(filter as LeafFilter, record, globalConstants);
      // eslint-disable-next-line eqeqeq
      return !values.some((value) => value == context[field]);
    }
    case "MISSING": {
      let { field } = filter as LeafFilter;
      return !context[field];
    }
    case "EXISTS": {
      console.log("archit EXISTS", filter, context);
      let { field } = filter as LeafFilter;
      console.log("archit EXISTS 2", filter, context, field, context[field]);
      return context[field] && !isEmptyObj(context[field]);
    }
    default:
      throw new Error(`Unsupported filter type: ${type}`);
  }
}
